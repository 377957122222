export enum CurrencyCloudPaymentTypes {
  PRIORITY = 'priority',
  REGULAR = 'regular',
}

export enum PaymentTypes {
  LOCAL = 'local',
  SWIFT = 'swift',
}

export enum ChargeTypes {
  SHARED = 'shared',
  OURS = 'ours',
  THEIRS = 'theirs',
}

export enum PaymentStatus {
  CREATED = 'CREATED',
  CONFIRMED = 'CONFIRMED',
}

export function RemapToInternalPaymentTypes(
  paymentType: CurrencyCloudPaymentTypes,
) {
  if (!paymentType) {
    return null;
  }

  switch (paymentType) {
    case CurrencyCloudPaymentTypes.PRIORITY:
      return PaymentTypes.SWIFT;
    case CurrencyCloudPaymentTypes.REGULAR:
      return PaymentTypes.LOCAL;
    default:
      return null;
  }
}

export function RemapToCurrencyCloudPaymentType(paymentType: PaymentTypes) {
  if (!paymentType) {
    return null;
  }

  switch (paymentType) {
    case PaymentTypes.LOCAL:
      return CurrencyCloudPaymentTypes.REGULAR;
    case PaymentTypes.SWIFT:
      return CurrencyCloudPaymentTypes.PRIORITY;
    default:
      return null;
  }
}
